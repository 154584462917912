@font-face {
	font-family: opensans light;
	src: url('/assets/Open_Sans/OpenSans-Light.ttf');
}
@font-face {
	font-family: opensans regular;
	src: url('/assets/Open_Sans/OpenSans-Regular.ttf');
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

body {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: none;
	animation: fadein 1s;
	font-family: opensans light;
	color: #333;
}

h1, h2, h3, h4, h5, h6, a, strong {
	font-family: opensans regular;
}

p, a {
	font-size: 16px;
	line-height: 150%;
	margin: 0;
}