body {
  width: 900px;
  margin: 40px auto;
  background: #f5f5fa;

  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 20px 5%;
  }
}

.header {
  padding: 0 0 150px 0;

  @media screen and (max-width: 900px) {
    margin: 20px 0 0 20px;
    padding: 0 0 75px 0;
  }

  h2 {
    font-size: 22px;
    color: #777;
    margin: 0;
    padding: 50px 0 0 0;
  }

  h1 {
    font-family: Georgia, serif;
    font-size: 50px;
    width: 450px;
    line-height: 110%;
    margin: 12px 0;
  }

  p {
    width: 400px;
    margin: 15px 0;

    &:first-child {
      float: right;

      @media screen and (max-width: 900px) {
        float: none;
      }
    }
  }

  a {
    display: inline-block;
    margin: 0 15px 0 0;
    border-radius: 10px;
    color: #000;
    text-decoration: none;

    &:nth-of-type(1) {
      padding: 9px 17px;
      background: #fd4;

      &:hover {
        padding: 7px 15px;
        border: #000 solid 2px;
      }
    }

    &:nth-of-type(2) {
      padding: 7px 15px;
      border: #000 solid 2px;

      &:hover {
        color: #f5f5fa;
        background: #000;
      }
    }
  }

  img {
    width: 400px;
    border-radius: 50%;
    margin: -20px 0 0 -20px;

    @media screen and (max-width: 900px) {
      margin: 20px 0 -30px 0;
    }
  }
}

.projects {
  margin: 0 0 100px 0;

  @media screen and (max-width: 900px) {
    padding: 0;
  }

  h3 {
    font-family: Georgia, serif;
    font-size: 40px;
    border-bottom: #fd4 solid 2px;
    width: 120px;
    margin: 0 0 50px 0;

    @media screen and (max-width: 900px) {
      margin: 0 0 50px 20px;
    }
  }

  .card {
    background: #fff;
    margin: 0 50px 50px 50px;
    height: 280px;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      margin: 0 0 50px 15px;
      width: 420px;
      height: auto;
    }

    img {
      width: 400px;
      float: right;
      border-radius: 0 10px 10px 0;
      padding: 0 0 0 40px;
      margin: -10px 0 0 0;

      @media screen and (max-width: 900px) {
        width: 420px;
        float: none;
        border-radius: 10px 10px 0 0;
        padding: 0;
        margin: 0;
      }
    }

    &:nth-of-type(2) img,
    &:nth-of-type(4) img {
      float: left;
      border-radius: 10px 0 0 10px;
      padding: 0 40px 0 0;

      @media screen and (max-width: 900px) {
        float: none;
        border-radius: 10px 10px 0 0;
        padding: 0;
      }
    }

    h4 {
      font-size: 20px;
      margin: 0;
      padding: 50px 40px 30px 40px;

      @media screen and (max-width: 900px) {
        padding: 20px 30px 20px 30px;
      }

      + p,
      + p + p {
        padding: 0 40px 20px 40px;

        @media screen and (max-width: 900px) {
          padding: 0 30px 20px 30px;
        }

        a {
          display: inline-block;
          border-radius: 20px;
          font-size: 15px;
          color: #000;
          text-decoration: none;
          padding: 6px 14px;
          border: #000 solid 1px;

          &:hover {
            color: #fff;
            background: #000;
          }

          @media screen and (max-width: 900px) {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}

.me {
  margin: 0 25px 0 25px;

  @media screen and (max-width: 900px) {
    margin: 0 15px;
  }

  h3 {
    font-family: Georgia, serif;
    font-size: 40px;
    border-bottom: #fd4 solid 2px;
    width: 65px;
    margin: 0 0 50px 0;
  }

  .card {
    background: #fff;
    margin: 0 25px;
    width: 375px;
    height: 700px;
    border-radius: 10px;
    float: left;

    @media screen and (max-width: 900px) {
      width: 420px;
      margin: 0 0 50px 0;
      padding: 0 0 10px 0;
      float: none;
      height: auto;
    }

    img {
      width: 375px;
      border-radius: 10px 10px 0 0;
      padding: 0 0 40px 0;

      @media screen and (max-width: 900px) {
        width: 420px;
        padding: 0 0 20px 0;
      }
    }

    h4 {
      font-size: 20px;
      margin: 0;
      padding: 0 40px 30px 40px;

      @media screen and (max-width: 900px) {
        padding: 0 30px 20px 30px;
      }

      + p,
      + p + p {
        padding: 0 40px 15px 40px;

        @media screen and (max-width: 900px) {
          padding: 0 30px 15px 30px;
        }
      }
    }
  }
}

.footer {
  clear: both;
  padding: 100px 0 0 0;
  text-align: center;

  @media screen and (max-width: 900px) {
    padding: 20px 0 30px 20px;
  }
}

.project {
  @media screen and (max-width: 900px) {
    margin: 90px 0 0 0;
  }

  h1 {
    font-family: Georgia, serif;
    font-size: 40px;
    border-bottom: #fd4 solid 2px;
    margin: 0 0 20px 0;

    @media screen and (max-width: 900px) {
      font-size: 30px;
    }

    + p a {
      display: block;
      border-radius: 20px;
      font-size: 15px;
      color: #000 !important;
      text-decoration: none !important;
      padding: 6px 14px;
      border: #000 solid 1px;
      float: right;

      &:hover {
        color: #fff !important;
        background: #000;
      }

      @media screen and (max-width: 900px) {
        position: absolute;
        top: 30px;
        left: 140px;
      }
    }
  }

  h2 {
    margin: 35px 0 15px 0;
    font-size: 22px;

    &::after {
      content: '';
      display: block;
      border-top:#fd4 solid 2px;
      width: 50px;
      margin: 5px 0 0 0;
    }
  }

  p {
    margin: 0 0 15px 0;
    white-space: pre-wrap;

    &:first-of-type {
      display: none;

      @media screen and (max-width: 900px) {
        display: block;
      }
    }

    img {
      max-width: 100%;
      margin: 10px 0 10px 0;
    }

    &:last-child {
      text-align: center;
      margin: 50px 0 100px 0;

      a {
        display: inline-block;
        margin: 0 15px 0 0;
        border-radius: 10px;
        color: #000;
        text-decoration: none;
        padding: 9px 17px;
        background: #fd4;

        &:hover {
          padding: 7px 15px;
          border: #000 solid 2px;
        }
      }
    }
  }

  table {
    margin: 0 0 15px 0;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  ul {
    margin: 0 0 15px 0;
    padding: 0 0 0 20px;
  }

  a {
    &:link, &:visited, &:hover, &:active {
      text-decoration: none;
      color: #eb0;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}